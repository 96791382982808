import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('not-found')
export class NotFoundView extends LitElement {
    static styles = css`
        
    `

    render() {

        return html`
            <p>Not found</p>
        `
    }
}
