import { Route } from "@common/routing"

const routes: Route[] =  [
    {
        path: "/",
        element: "home-view",
    },
    {
        path: "earth",
        element: "earth-view",
    },
    {
        path: "map",
        element: "map-view",
    }
]
export default routes