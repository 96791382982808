import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import {classMap} from "lit/directives/class-map.js"
import { signalrState } from "@app/services/signalrService"
import { observe } from "@common/directives/observableDirective"
import { faWifi } from "@fortawesome/free-solid-svg-icons"

@customElement('network-state')
export class NetworkState extends LitElement {
    static styles = css`
        :host {
            display: block;
            position: relative;
        }
        .online {
            color: var(--digilean-success);
        }
        .offline {
            color: var(--digilean-error);
        }
        .notsignalr {
            color: var(--digilean-warning);
        }
        .size20 {
            font-size: 20px;
        }
        .size25 {
            font-size: 25px;
        }
        .size30 {
            font-size: 30px;
        }
        .size35 {
            font-size: 35px;
        }
        .size40 {
            font-size: 40px;
        }
    `

    render() {
        return observe(signalrState, (state: SignalRConnectionState) => {
            const classes = {
                "size30": true,
                "online": state.isConnected,
                "offline": !state.isConnected
            }
            let title = state.isConnected ? "SignalR connected" : "NOT connected"
            //this.setAttribute("title", title)

            if (state.isConnected) {
                title = `${title}<br>url:${state.baseUrl?.substring(0, 20)}<br>url2:${state.actualUrl?.substring(0, 20)}`
            }
            return html`
                <tool-tip content=${title}></tool-tip>
                <fa-icon class=${classMap(classes)} 
                    .icon=${faWifi}
                    title=${title}>
                </fa-icon>
            `
            
            
        })
    }
}
