<script setup lang="ts">
    import { ref } from "vue"

    const showPopup = ref(false)
    const showPopup2 = ref(false)
    const showPopover = ref(false)

    let toggle = () => {
        showPopup.value = !showPopup.value
    }
    let toggle2 = () => {
        showPopup2.value = !showPopup2.value
    }

    let togglePopover = () => {
        showPopover.value = !showPopover.value
    }

</script>
<style>
    .wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        background: orange;
        border: 2px solid white;
    }
    .absolutist {
        background: grey;
        color: red;
        position: absolute;
        width: 500px;
        height: 100px;
    }
    .content {
        background: blue;
        color: white;
    }
    digilean-popup2 {
        margin-top: 10rem;
    }
</style>
<template>
    <div class="wrapper">
        <div class="absolutist">
            <digilean-popup .show="showPopup">
            <button slot="button" @click="toggle">Toggle DigiLEAN popup</button>
            <div slot="content" class="content">
                <p>Content 1</p>
                <p>Content 1</p>
                <p>Content 1</p>
                <p>Content 1</p>
            </div>
        </digilean-popup>
        </div>

        <digilean-popup2 .show="showPopup2">
            <button slot="button" @click="toggle2">pop2</button>
            <div slot="content" class="content">
                <p>Hello hello hello Hello hello hello</p>
                <p>Hello hello hello Hello hello hello</p>
            </div>
        </digilean-popup2>

        <button @click="togglePopover">Show SP popover</button>
        <sp-popover dialog tip placement="bottom" .open="showPopover">
            <div>
                Popover title
            </div>
            <div style="font-size: 14px">
                Cupcake ipsum dolor sit amet jelly beans. Chocolate jelly caramels.
                Icing soufflé chupa chups donut cheesecake. Jelly-o chocolate cake
                sweet roll cake danish candy biscuit halvah
            </div>
        </sp-popover>
    <!--
        <br>
        <overlay-trigger>
            <sp-button variant="primary" slot="trigger">Open popover</sp-button>
            <sp-popover dialog slot="click-content" 
            direction="bottom"
            open tip 
            variant="default"
            placement="left">
                <div style="background:blue">
                    <p>Content 1</p>
                </div>
            </sp-popover>
        </overlay-trigger>
    -->
    </div>
</template>