import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('map-view')
export class MapView extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
        }
        world-map {
            display: block;
            width: 100%;
            height: 100%;
        }
    `

    render() {

        return html`
            <world-map></world-map>
        `
    }
}
