import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('home-view')
export class HomeView extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
        }
        .split {
            flex: 1;
        }
        .customer {
            padding: 1rem;
        }
    `

    render() {

        return html`
            <div class="split">
                <digilean-3d-spinner></digilean-3d-spinner>
            </div>
            <div class="split">
                <p>Hello world</p>
            </div>
        `
    }
}
