import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {unsafeHTML} from "lit/directives/unsafe-html.js"

@customElement('tool-tip')
export class TooltipComponent extends LitElement {
    static styles = css`
        :host {
            display: block;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            z-index: 100;
            width: 10vw;
            max-width: 10%;
            font-size: small;
        }
        :host(:hover) {
            visibility: visible;
            opacity: 1;
        }
        div {
            margin-top: 1rem;
        }
    `

    @property({attribute: true})
    declare content: string | undefined

    render() {
        return html`
            <div>
                ${this.content ? unsafeHTML(this.content) : ""}
            </div>
        `
    }
}
