import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import {chew} from "@common/services/customerChewer"

import { CustomerSignup } from "@api"
@customElement('customer-list')
export class CustomerList extends LitElement {
    // test = {
    //     customerId: 2,
    //     email: "atle@digilean.com",
    //     firstName: "atle",
    //     isTeams: true,
    //     lastName: "Magnussen",
    //     name: "Atle Magnussen",
    //     signupDate: new Date().toISOString(),
    //     subscription: "PROFESSIONAL"
    // }
    private trials: CustomerSignup[] = []

    static styles = css`
        :host {
            display: block;
        }
    `

    connectedCallback() {
        super.connectedCallback()
        // signalrEvents.subscribe(event => {
        //     console.log(event.eventName)
        //     if (event.eventName == "TrialRegistration") {
        //         const data = event.payload as CustomerSignup
        //         console.log(data)
        //         this.trials.push(data)
        //         this.requestUpdate()
        //     }
        // })
    }

    async getData() {
        const ta = this.shadowRoot?.querySelector("textarea")
        const data = await chew()
        const dataStr = JSON.stringify(data, null, 4)
        ta!.value = dataStr
    }
    render() {

        return html`
            <p>Customers:</p>
            <digilean-button @click=${this.getData}>Fetch</digilean-button>
            <textarea>${JSON.stringify(this.trials, null, 2)}</textarea>
            <div>
                ${this.trials.map(t => {
                    return html`
                        <p>
                            customerId: ${t.customerId}<br>
                            email: ${t.email}<br>
                            firstName: ${t.firstName}<br>
                            isTeams: ${t.isTeams}<br>
                            lastName: ${t.lastName}<br>
                            name: ${t.name}<br>
                            signupDate: ${t.signupDate}<br>
                            subscription: ${t.subscription}
                        </p>
                    `
                })}
            </div>
        `
    }
}
