//@ts-ignore
import nmparser from "nameamparser"
import { GetCustomersForAdmin } from "@common/services/customers"
import { resolve } from "@common/services/topdomainResolver"
import { CountryCount, CustomerStat } from "@common/model/moreTypes"

export const chew = async () => {
    const data = await GetCustomersForAdmin()

    const dataMap = data.map(d => {
        const cusName = d.customer?.name
        let countrySuffix = "no"
        let country: string | null = ""
        if (nmparser.includesTld(cusName)) {
            const url = nmparser.parse(cusName)
            countrySuffix = url.tld
            
            country = resolve(countrySuffix)
        }

        return {
            countrySuffix,
            country,
            name: cusName,
            userLicenses: d.account?.userLicenses,
            isTrial: d.account?.isTrial,
            azureAdTenantId: d.customer?.azureAdTenantId
        }
    }) as CustomerStat[]

    const sumMap = dataMap.map(d => {
        return d.country
    }).reduce((sum: any, r: string, i: number) => {
        if(!sum[r])
            sum[r] = 0
        sum[r] += 1
        return sum
    }, {})
    
    const sumObject = Object.keys(sumMap).map(s => {
        const customers: number = sumMap[s]
        return {
            country: s,
            customers
        } as CountryCount
    })

    return sumObject
}