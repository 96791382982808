import * as signalR from "@microsoft/signalr"
import { BehaviorSubject } from "rxjs"
import config from "@common/config"
import { authUser } from "@common/stores/userStore"
import { DigiLeanAuthUser, SignalRConnectionState, SignalrEvent } from "@common/model/types"


const stateSubject = new BehaviorSubject<SignalRConnectionState>({isConnected: false, id: ""})
export const signalrState = stateSubject.asObservable()
const eventsSubject = new BehaviorSubject<SignalrEvent>({eventName: "", payload: ""})
export const signalrEvents = eventsSubject.asObservable()

let systemHub: signalR.HubConnection
let token: string

const systemHubUrl = config.signalrUrl + "/system"
let connectionId = ""
let connected = false

function setSignalRConnection(conn: boolean, connId: string) {
    connectionId = connId
    connected = conn
    
    let state: SignalRConnectionState = {
        isConnected: connected,
        id: connectionId
    }
    
    try {
        //@ts-ignore
        state.actualUrl = systemHub.connection.transport._webSocket.url
    } catch (e) {}
    
    stateSubject.next(state)
}

const connectSystemHub = async () => {
    console.log('Connecting')
    await systemHub.start().catch(e => {
        console.error(e)
    })
    if (systemHub.state === signalR.HubConnectionState.Connected) {
        onConnected(systemHub.connectionId!);
        connectSystemHubEvents()
    }
}

function onConnected(connId: string) {
    console.log('Connected: ' + connectionId)
    systemHub.invoke("SendUserConnectedRequest")
    setSignalRConnection(true, connId)
}
async function setupOrReConnect(user: DigiLeanAuthUser) {
    token = user.token!
    if (!systemHub)
        return setupAndConnect()
    if (systemHub && (systemHub.state == signalR.HubConnectionState.Connected || systemHub.state == signalR.HubConnectionState.Connecting))
        return
    return connectSystemHub()
}
async function setupAndConnect() {
    if (systemHub && systemHub.state !== signalR.HubConnectionState.Disconnected)
        return;
    
    var builder = new signalR.HubConnectionBuilder();

    systemHub = builder.withUrl(systemHubUrl,{
        accessTokenFactory: () => {
            return token
        }
    }).build();
    
    await connectSystemHub();
    
    systemHub.onclose(async (error) => {
        console.log(error)
        console.log('Disconnected')
        setSignalRConnection(false, "")
        // Try to reconnect, unless the user is logging out
        if (!token) {
            setTimeout(async () => {
                await connectSystemHub();
            }, 5000);
        } else {
            console.log("logged out")
        }
    })
    systemHub.onreconnecting((error) => {
        console.log(error)
        setSignalRConnection(false, "")
        console.log('Reconnection');
    })
    systemHub.onreconnected((connId) => {
        onConnected(connId!);
    })
}
let eventsListen = ["Notification", "TaskAssigned", "TrialRegistration"]
const connectSystemHubEvents = () => {
    eventsListen.map(evt => {
        systemHub.on(evt, (payload) => {
            publishSignalrEvent(evt, payload)
        })
    })
}

const publishSignalrEvent = (eventName: string, payload: any) => {
    let message: SignalrEvent = {
        eventName,
        payload
    }
    eventsSubject.next(message)
}

authUser.subscribe(user => {
    if (user.loggedIn)
        setupOrReConnect(user)
})