<script setup lang="ts">
    import { onMounted, ref } from "vue"
    import { loadTopoMapData, getMapOptions, Highcharts } from "./MapLoader"

    const mapEl = ref<HTMLDivElement | null>(null)

    const showMap = async (mapData: any) => {

        if (!mapData)
            return
        if (!mapEl.value)
            return
        if (!Highcharts)
            return

        const mapElement = mapEl.value
        
        const options = await getMapOptions(mapData)
        Highcharts.mapChart(mapElement, options)
        
    }

    const load = async () => {
        const mapData = await loadTopoMapData()
        if (!mapData) {
            if (Highcharts.charts[0]) {
                Highcharts.charts[0].showLoading(
                    '<i class="fa fa-frown"></i> Map not found'
                )
            }
            return
        }
        showMap(mapData)
    }
    
    onMounted(() => {
        load()
    })

</script>
<template>
    <div class="map" ref="mapEl"></div>
</template>
<style>
    div.map {
        height: 100%;
        width: 100%;
    }
</style>