import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import "@app/css/site.css"
import "./imports"
import digiOidc from "@common/authentication/digiLeanOidc"
import "@app/routing"
import { goto, gotoPath } from "@app/routing/router"

import { faGlobe, faEarth } from "@fortawesome/free-solid-svg-icons"

digiOidc.initialize()

@customElement('main-app')
export class MainAppComponent extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
        header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.5rem 1.5rem;
        }
        figure {
            padding: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
        }
        fa-icon {
            font-size: 25px;
        }
        svg {
            width: 25px;
            height: 25px;
        }
        main {
            flex: 1;
        }
        .links {
            display: inline-flex;
            flex-direction: row;
            gap: 1rem;
        }
    `

    render() {

        return html`
            <header>
                <div class="links">
                    <a href="/" @click=${goto}>
                        <digilean-logo></digilean-logo>
                    </a>
                    <a href="/earth" @click=${goto}>
                        <fa-icon .icon=${faGlobe}></fa-icon>
                    </a>
                    <a href="/map" @click=${goto}>
                        <fa-icon .icon=${faEarth}></fa-icon>
                    </a>
                </div>
                <network-state></network-state>
                <login-comp></login-comp>
            </header>
            <main role="main">
                <container-view></container-view>
            </main>
        `
    }
}
gotoPath(window.location.pathname)
// import { materialIconsStyle } from "@common/style/stylesheet"
// const styleEl = document.createElement("style")
// const codeEl = document.createTextNode(materialIconsStyle)
// styleEl.appendChild(codeEl)
// document.head.appendChild(styleEl)

