import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('earth-view')
export class EarthView extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
        }
    `

    render() {

        return html`
            <digilean-earth></digilean-earth>
        `
    }
}

import "@app/views/notFound"
import "@app/views/listCustomers"