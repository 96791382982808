import Highcharts from "highcharts"
import useHighchartsMap from "highcharts/modules/map"
import useAccessibility from "highcharts/modules/accessibility"
import { chew } from "@common/services/customerChewer"

useHighchartsMap(Highcharts)
useAccessibility(Highcharts)

export { Highcharts }

const baseMapPath = "https://code.highcharts.com/mapdata/"
const mapKey = "custom/world"
const svgPath = baseMapPath + mapKey + ".svg"
const geojsonPath = baseMapPath + mapKey + ".geo.json"
const topojsonPath = baseMapPath + mapKey + ".topo.json"
const javascriptPath = baseMapPath + mapKey + ".js"


let showDataLabels = false
const formatter = function () {
    return "allo"
}

export const getMapOptions = async (mapData: any) => {

    const customers = await chew()

    const data = mapData.objects.default.geometries.map((g, i) => {
        const key = g.properties['hc-key']
        const name = g.properties['name']
        const isoA3 = g.properties['iso-a3']

        const found = customers.find(c => c.country == name || c.country == isoA3)

        const value = found ? found.customers : 0

        return {key, value}
    })

    const hOptions = Highcharts.getOptions()
    if (!hOptions || !hOptions.colors || hOptions.colors.length < 2)
        return
    
    const options: Highcharts.Options = {
        chart: {
            map: mapData,
            backgroundColor: '#222',
            borderColor: "#333",
            plotBorderColor: "#444",
            animation: true
        },
        title: {
            text: "DigiLEAN"
        },
        accessibility: {
            series: {
                descriptionFormat: '{series.name}, map with {series.points.length} areas.',
                pointDescriptionEnabledThreshold: 50
            }
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                alignTo: 'spacingBox',
                x: 10
            }
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'bottom'
        },
        series: [
        // {
        //     name: 'Map',
        //     type: "area",
        //     fillColor: "#000",
        //     borderColor: '#666',
        //     dataLabels: {
        //         enabled: true,
        //         nullFormat: '{point.name}',
        //         style: {
        //             color: '#888',
        //             textOutline: 'none',
        //             fontSize: '16px',
        //             fontWeight: 'normal'
        //         }
        //     }
        // },
        {
            data,
            joinBy: ['hc-key', 'key'],
            name: 'Customers count',
            states: {
                hover: {
                    color: hOptions.colors[2]
                }
            },
            dataLabels: {
                enabled: showDataLabels,
                formatter,
                style: {
                    fontWeight: 100,
                    fontSize: '10px',
                    textOutline: 'none'
                }
            }
        }, 
        {
            type: 'mapline',
            name: "Lines",
            accessibility: {
                enabled: false
            },
            data: Highcharts.geojson(mapData, 'mapline'),
            /*
            data: [{
                geometry: mapData.objects.default['hc-recommended-mapview'].insets[0].geoBounds
            }],
            // */
            nullColor: '#444',
            fillColor: "#000",
            showInLegend: false,
            enableMouseTracking: false
        }],
        colorAxis: {
            min: 0,
            stops: [
                [0, '#EFEFFF'],
                [0.5, hOptions.colors[0]],
                [
                    1,
                    Highcharts.color(hOptions[0])
                        .brighten(-0.5).get()
                ]
            ]
        },
    }

    return options
}


const getMapData = async (url: string) => {
    return await fetch(url)
    .then(response => {
        return response.json()
    })
    .catch(e => console.log('Error', e));
}

export const loadTopoMapData = async () => {
    const data = await getMapData(topojsonPath)
    return data
}