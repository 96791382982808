import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

import { authUser } from "@common/stores/userStore"
import { observe } from "@common/directives/observableDirective"
import oidc from "@common/authentication/digiLeanOidc"


@customElement('login-comp')
export class LoginComp extends LitElement {
    static styles = css`
        :host {
            display: block;
            --digilean-image-width: 2rem;
            --digilean-image-height: 2rem;
        }
        div.logout {
            padding: 0.4rem;
        }
        span.menuitem {
            cursor: pointer;
        }
        
    `

    render() {
        return observe(authUser, (user: AuthUser) => {
            if (user.loggedIn) {
                return html`
                    <popdown-menu>
                        <logged-in-profile-image-viewer slot="button"></logged-in-profile-image-viewer>
                        <div slot="content" class="logout">
                            <span class="menuitem" @click=${oidc.signOut}>Log out</span>
                        </div>
                    </popdown-menu>
                `
            }
            return html`
                <digilean-button @click=${oidc.login}>Login</digilean-button>
            `
        })
    }
    
}
