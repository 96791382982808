import { defineCustomElement } from "vue"

import "@common/components"
import "@common/components/Formatters"
import "@common/components/Images"

import "@app/components/loginComponent"
import "@app/components/networkState"
import "@app/components/tooltip"
import "@app/components/digilean3dSpinner/component"
import "@app/components/digieanEarth/component"

import "@common/services/userService"

import "@app/views"
import "@app/views/earth"
import "@app/views/map"

import "@spectrum-web-components/theme/sp-theme.js"
import "@spectrum-web-components/theme/src/themes.js"
import "@spectrum-web-components/overlay/active-overlay.js"
import "@spectrum-web-components/overlay/overlay-trigger.js"
import "@spectrum-web-components/button/sp-button.js"
import "@spectrum-web-components/popover/sp-popover.js"

import DigiLeanWorldMap from "@common/components/Maps/DigiLeanWorldMap.vue"
customElements.define("world-map", defineCustomElement(DigiLeanWorldMap))

import Hello from "@common/components/Hello.vue"
customElements.define("hello-vue", defineCustomElement(Hello))

import TestVue from "@common/components/TestView.vue"
customElements.define('test-vue', defineCustomElement(TestVue))